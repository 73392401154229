import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { config } from './config/config';
import JoditEditor from 'jodit-react';
import { Appbar } from './components';
import { getHeader, getFooter, getBody } from './helpers';
import { templates, templateModalities } from './templates/templates';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Button } from '@ohif/ui';
import { useAuthenticationContext, useXylexaAppContext } from './../context';
import { useSubmitStudyReport } from '../api-client';
import { useToast } from '@xylexa/xylexa-app';
import mixpanel from 'mixpanel-browser';

export const AddReport = () => {
  const editor = useRef(null);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [isAIReportPreview, setIsAIReportPreview] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [newReportContent, setNewReportContent] = useState<string>('');
  const { userInfo } = useAuthenticationContext();
  const { showToast } = useToast();

  const navigate = useNavigate();

  const {
    selectedTemplate,
    setSelectedTemplate,
    selectedModality,
    setSelectedModality,
    changeInReportDetected,
    setChangeInReportDetected,
    selectedStudy,
    setIsNewReport,
    isInsideViewer,
  } = useXylexaAppContext();

  const { mutate: handleSubmitReport } = useSubmitStudyReport();

  useEffect(() => {
    setNewReportContent(
      `${getHeader(selectedStudy, '')}<br/>${getBody(selectedModality, selectedTemplate?.id)}${getFooter()}`
    );
  }, [selectedStudy]);

  useEffect(() => {
    const handleUnload = e => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  // Report Submission function
  const handleSubmit = () => {
    const body = {
      study_id: selectedStudy.studyInstanceUid,
      patient_id: selectedStudy.mrn,
      patient_name: selectedStudy.patientName,
      description: newReportContent,
    };

    handleSubmitReport(body, {
      onSuccess: () => {
        showToast({
          content: 'Report Submitted',
          type: 'success',
        });
        setChangeInReportDetected(false);
        setIsNewReport(false);
        setSelectedTemplate('');
        setSelectedModality('Default');
        navigate(`/viewer/view-report/${selectedStudy.studyInstanceUid}`);
        mixpanel.track('Report Submitted', {
          studyId: selectedStudy.studyInstanceUid,
          modality: selectedStudy.modalities,
          userId: userInfo?.id,
          userEmail: userInfo.email,
        });
      },
      onError() {
        showToast({
          content: 'Report Submission Failed',
          type: 'error',
        });
      },
    });
  };

  // Handle report submisison on clicking Yes, in confirmation dialogue
  const handleBackWithSave = () => {
    const body = {
      study_id: selectedStudy.studyInstanceUid,
      patient_id: selectedStudy.mrn,
      patient_name: selectedStudy.patientName,
      description: newReportContent,
    };

    handleSubmitReport(body, {
      onSuccess: res => {
        setChangeInReportDetected(false);
        setIsNewReport(false);
        setSelectedTemplate('');
        setSelectedModality('Default');
        showToast({
          content: 'Report Submitted',
          type: 'success',
        });
        if (isInsideViewer) {
          navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
          mixpanel.track('Report Submitted', {
            studyId: selectedStudy.studyInstanceUid,
            modality: selectedStudy.modalities,
            userId: userInfo?.id,
            userEmail: userInfo.email,
          });
          return;
        } else {
          navigate('/');
          mixpanel.track('Report Submitted', {
            studyId: selectedStudy.studyInstanceUid,
            modality: selectedStudy.modalities,
            userId: userInfo?.id,
            userEmail: userInfo.email,
          });
          return;
        }
      },
      onError(error) {
        showToast({
          content: 'Report Submission Failed.',
          type: 'error',
        });
      },
    });
  };

  const handleBackWithoutSave = () => {
    setSelectedTemplate('');
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);

    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  };

  // Confirmation Dialogue
  function handleBackWithoutSaveNoChangeDetected() {
    setSelectedTemplate('');
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);
    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  }
  const handleOpenDialog = () => {
    if (!changeInReportDetected) {
      handleBackWithoutSaveNoChangeDetected();
      return;
    }
    if (changeInReportDetected) {
      setOpenDialog(true);
      return;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div className="min-h-screen bg-white">
        <Appbar
          flag={flag}
          setFlag={setFlag}
          isNew={isNew}
          setIsNew={setIsNew}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          templateOptions={templates[selectedModality?.value]}
          templateModalities={templateModalities}
          signatures={[]}
          selectedModality={selectedModality}
          setSelectedModality={setSelectedModality}
          handleOpenDialog={handleOpenDialog}
          handleSubmit={handleSubmit}
          isAIReportPreview={isAIReportPreview}
          setIsAIReportPreview={setIsAIReportPreview}
        />

        <div style={{ marginTop: '80px', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
          <JoditEditor
            ref={editor}
            value={newReportContent}
            config={config}
            onChange={newContent => {
              setChangeInReportDetected(true);
              setNewReportContent(newContent);
            }}
          />
        </div>
      </div>

      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <h1 className="text-xl font-semibold text-black">Do you want to save changes?</h1>
        <p className="text-lg text-black">
          If you choose "No", the content will be deleted permanently, else the report will be
          saved.
        </p>
        <br />
        <div className="flex justify-end gap-2">
          <Button onClick={handleBackWithoutSave}>No</Button>
          <Button onClick={handleBackWithSave}>Yes</Button>
        </div>
      </Modal>
    </>
  );
};
export default AddReport;
